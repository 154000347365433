import PropTypes from 'prop-types';
// @mui
import {Button, Dialog, DialogTitle, DialogActions, DialogContent, TextField, MenuItem, Stack} from '@mui/material';
import { _attendance, _survey } from '../../../../util/course.js';

// ----------------------------------------------------------------------

export default function AttendanceDialog({ title, content, setSurvey, surveryValue,  action, open, onClose, ...other }) {

  const onChangeSurvey = (e) => {
    const { name, value } = e.target; // Extract name and value from the event
    setSurvey((prevSurvey) => ({
      ...prevSurvey, // Keep the previous state
      [name]: value  // Update only the changed property
    }));
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>
  
         <DialogContent sx={{ typography: 'body2' }}>
         <Stack
            spacing={2}
            direction={{ xs: 'column', sm: 'row' }}
            sx={{ p: 3, bgcolor: 'background.neutral' }}
            >
        <TextField onChange={onChangeSurvey} select name="attendance" value={surveryValue?.attendance} label="Attendance" sx={{
            width: "33.33%"
        }}>
            {_attendance?.map((res) => {
                return (
                    <MenuItem key={res?.code} value={res.code} >{res?.description}</MenuItem>
                )
            })}
        </TextField>
        <TextField onChange={onChangeSurvey} select name="surveyLanguage" value={surveryValue?.surveyLanguage} label="Survey" sx={{
            width: "33.33%"
        }}>
            {_survey?.map((res) => {
                return (
                    <MenuItem key={res?.code} value={res.code} >{res?.description}</MenuItem>
                )
            })}
        </TextField>
        <TextField onChange={onChangeSurvey} name="numberOfHours" type='number'  label="Number of Hours" helperText="Applicable for OJT only."  sx={{
            width: "33.33%"
        }}/> 
        </Stack>


         </DialogContent>
            
      <DialogActions>
        {action}

        <Button variant="outlined" color="inherit" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AttendanceDialog.propTypes = {
  action: PropTypes.node,
  content: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  setSurvey: PropTypes.func
};
