import React, { useState } from "react";
import { TextField, Button, MenuItem, Select, FormControl, InputLabel, Checkbox, FormControlLabel, Typography, Card, Grid, Container } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useBoolean } from '../../../hooks/use-boolean.js';
import { FormBuilderDialog } from '../../../components/form-builder/index.js';

const FormBuilder = () => {
  const [fields, setFields] = useState([]);
  const [formPreview, setFormPreview] = useState([]);
  const confirm = useBoolean();

  const addGrid = (parentId, size) => {
    const newGrid = { id: Date.now(), type: "grid", gridSize: size, children: [] };
    if (parentId === null) {
      setFields([...fields, newGrid]);
    } else {
      setFields(fields.map(field =>
        field.id === parentId ? { ...field, children: [...field.children, newGrid] } : field
      ));
    }
  };

  const addComponentToGrid = (gridId, type) => {
    setFields(fields.map(field =>
      field.id === gridId ?
        { ...field, children: [...field.children, { id: Date.now(), type, label: "", gridSize: 4 }] } :
        field
    ));
  };

  const generateForm = () => {
    setFormPreview(fields);
  };

  const renderFields = (fields) => (
    <Grid container spacing={2}>
      {fields.map((field) => (
        <Grid item xs={field.gridSize || 12} key={field.id}>
          {field.type === "grid" ? (
            <Card variant="outlined" style={{ padding: 10, marginBottom: 10 }}>
              <Typography>Grid {field.gridSize}</Typography>
              {[1, 2, 3, 4, 6, 12].map(size => (
                <Button key={size} variant="outlined" onClick={() => addGrid(field.id, size)} style={{ marginRight: 5 }}>Add Grid {size}</Button>
              ))}
              <Button variant="outlined" onClick={() => addComponentToGrid(field.id, "text")} style={{ marginRight: 5 }}>Add Textbox</Button>
              <Button variant="outlined" onClick={() => addComponentToGrid(field.id, "multiText")} style={{ marginRight: 5 }}>Add Multi Textbox</Button>
              <Button variant="outlined" onClick={() => addComponentToGrid(field.id, "date")} style={{ marginRight: 5 }}>Add Datepicker</Button>
              <Button variant="outlined" onClick={() => addComponentToGrid(field.id, "select")} style={{ marginRight: 5 }}>Add Dropdown</Button>
              {renderFields(field.children)}
            </Card>
          ) : field.type === "text" ? (
            <TextField label={field.label || "Textbox"} fullWidth />
          ) : field.type === "multiText" ? (
            <TextField label={field.label || "Multi Textbox"} fullWidth multiline rows={3} />
          ) : field.type === "date" ? (
            <DesktopDatePicker label={field.label || "Date Picker"} inputFormat="MM/dd/yyyy" renderInput={(params) => <TextField {...params} fullWidth />} />
          ) : field.type === "select" ? (
            <FormControl fullWidth>
              <InputLabel>{field.label || "Select Option"}</InputLabel>
              <Select>
                <MenuItem value="Option 1">Option 1</MenuItem>
                <MenuItem value="Option 2">Option 2</MenuItem>
              </Select>
            </FormControl>
          ) : null}
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Container maxWidth="xl" style={{ padding: 20 }}>
      <Typography variant="h4">Form Builder</Typography>
      <div style={{ marginBottom: 20 }}>
        {[1, 2, 3, 4, 6, 12].map(size => (
          <Button key={size} variant="contained" onClick={() => addGrid(null, size)} style={{ marginRight: 10 }}>Add Grid {size}</Button>
        ))}
      </div>
      <Card variant="outlined" style={{ padding: 20, marginBottom: 20 }}>
        <Typography variant="h6">Configure Fields</Typography>
        {renderFields(fields)}
      </Card>
      <Button variant="contained" color="primary" onClick={generateForm}>Generate Form</Button>
      <Card variant="outlined" style={{ padding: 20, marginTop: 20 }}>
        <Typography variant="h6">Form Preview</Typography>
        {renderFields(formPreview)}
      </Card>
        <Button onClick={() => confirm.onTrue()} >Create Auditing Form</Button>

          <FormBuilderDialog
              open={confirm.value}
              onClose={confirm.onFalse}
              title="Create Auditing Form"
              content={`Are you sure want to delete?`}
              action={
                <Button variant="contained" color="info" >
                  Save
                </Button>
              }
            />


    </Container>
  );
};

export default FormBuilder;
