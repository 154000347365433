import { Helmet } from "react-helmet-async";
import FormBuilder from "../../sections/auditing/view/custom-form.js"

export default function AuditingManagementPage(){
    return (
        <>
            <Helmet>
                <title>Auditing Management Page</title>
            </Helmet>
            <FormBuilder />
        </>
    )
}