import PropTypes from 'prop-types';
// @mui
import {Button, Dialog, DialogTitle, DialogActions, DialogContent, Container, Grid, Typography} from '@mui/material';
import Iconify from 'src/components/iconify/iconify';

// ----------------------------------------------------------------------

export default function FormBuilderDialog({ title, content, action, open, onClose, ...other }) {
  return (
    <Dialog fullWidth fullScreen open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>
      <Container maxWidth="xl" style={{ padding: 20 }}>
      <Grid container spacing={2}>
        <Grid item md={3}>
                <Typography variant='h6'>Basic</Typography>
                <Grid item md={6}><Button startIcon={<Iconify icon="bi:textarea-t" />}>Label</Button></Grid>
                <Grid item md={6}><Button startIcon={<Iconify icon="solar:text-field-bold-duotone" />}>TextBox</Button></Grid>
                <Grid item md={6}><Button startIcon={<Iconify icon="solar:calendar-date-bold-duotone" />}>DatePicker</Button></Grid>
                <Grid item md={6}><Button startIcon={<Iconify icon="material-symbols:select-check-box" />}>Select</Button></Grid>
                <Grid item md={6}><Button startIcon={<Iconify icon="ri:radio-button-line" />}>Radio</Button></Grid>
                <Typography variant='h6'>Layout</Typography>
                <Grid item md={6}><Button startIcon={<Iconify icon="fluent:layout-column-two-16-regular" />}>Two Column</Button></Grid>
                <Grid item md={6}><Button startIcon={<Iconify icon="fluent:layout-column-three-16-regular" />}>Three Column</Button></Grid>
                <Grid item md={6}><Button startIcon={<Iconify icon="fluent:layout-column-four-24-regular" />}>Four Column</Button></Grid>
        </Grid>
        <Grid item md={9}>
            Preview
        </Grid>
      </Grid>
      </Container>

      <DialogActions>
        {action}
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FormBuilderDialog.propTypes = {
  action: PropTypes.node,
  content: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
};
